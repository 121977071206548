import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAGkiTQCRC-z-WtSS9gg8fo0cY1rmHSgVc",
  authDomain: "rh-virtual-d4a3a.firebaseapp.com",
  databaseURL: "https://rh-virtual-d4a3a.firebaseio.com",
  projectId: "rh-virtual-d4a3a",
  storageBucket: "rh-virtual-d4a3a.appspot.com",
  messagingSenderId: "230138619783",
  appId: "1:230138619783:web:3a892cea25718a575e1522",
  measurementId: "G-B9QZWQGT1M",
  debug: true,
  dev: false,
};
firebase.initializeApp(firebaseConfig);
export default firebase;