import React, { Component } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";

import Select from "react-select";

import firebase from "../../firebase.js";
require("firebase/database");

export default class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      email: "",
      phone: "",
      city: "",
      creci: false,
      agendamento: "",

      agendamentoArr: [],

      submitIsLoading: false,
    };
  }

  componentDidMount() {
    let self = this;
    firebase
      .database()
      .ref()
      .child("datas-de-agendamento")
      .on("child_added", (snap) => {
        if (snap) {
          self.setState({
            agendamentoArr: [...self.state.agendamentoArr, snap.val()],
          });
        }
      });
  }

  handleChange = (e) => {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.nome !== "" &&
      this.state.email !== "" &&
      this.state.phone !== "" &&
      this.state.city !== ""
    ) {
      let sorteioRef = firebase.database().ref().child("sorteio");
      sorteioRef.once("value", (snap) => {
        if (snap) {
          const key = firebase
            .database()
            .ref()
            .child("leads-rh")
            .child(this.state.email.toString().split(".").join(""))
            .set(
              {
                nome: this.state.nome,
                email: this.state.email,
                phone: this.state.phone,
                city: this.state.city,
                creci: this.state.creci,
                origem: "Landing Page",
                status: "Sem Contato",
                diretor: snap.val().diretores[0],
                excluido: false,
                agendamento: this.state.agendamento,
                responsavel: snap.val().rh[0],
                timestamp: new Date().toString(),
                unixTimestamp: Math.round(new Date().getTime() / 1000),
              },
              (error) => {
                if (error) {
                  console.log(error);
                  alert(
                    "Ocorreu um erro. Por favor, verifique sua conexão com a internet."
                  );
                } else {
                  sorteioRef.set({
                    diretores: {
                      0: snap.val().diretores[1],
                      1: snap.val().diretores[2],
                      2: snap.val().diretores[3],
                      3: snap.val().diretores[0],
                    },
                    rh: {
                      0: snap.val().rh[1],
                      1: snap.val().rh[0],
                    },
                  });
                  alert(
                    "Formulário enviado com sucesso! Aguarde que logo entraremos em contato com você."
                  );
                  this.setState({
                    nome: "",
                    email: "",
                    phone: "",
                    city: "",
                    creci: false,
                  });
                }
              }
            ).key;
        }
      });
    } else {
      alert("Por favor, preencha todos os campos.");
    }
  };

  agendamentoOption = () => {
    return this.state.agendamentoArr.map((item, i) => {
      return {
        value: i.toString(),
        label:
          item.weekDay + " " + item.date.split("-").join("/") + " " + item.time,
      };
    });
  };

  render() {
    return (
      <div>
        <div className="header-2">
          <Navbar
            className="navbar-transparent bg-info burger-menu navbar-absolute"
            expand="lg"
          >
            <Container>
              <div className="navbar-translate">
                <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                  Kallas Vendas
                </NavbarBrand>
              </div>
            </Container>
          </Navbar>

          <div className="page-header header-filter">
            <div
              className="page-header-image"
              style={{
                backgroundImage: "url(" + require("assets/img/suit.jpg") + ")",
                zIndex: "-10",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  zIndex: "-5",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            </div>
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h1 className="title">
                    Se torne um Corretor de Imóveis <i>KV!</i>
                  </h1>
                  <h5 className="description">
                    <b>Nós pagamos 70% do curso</b> TTI necessário para tirar o
                    CRECI!
                  </h5>
                </Col>
                <Col className="ml-auto mr-auto" md="12">
                  <Card
                    className="card-raised card-form-horizontal card-plain"
                    data-background-color=""
                  >
                    <CardBody>
                      <Form action="" method="">
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                autoComplete="family-name"
                                onChange={this.handleChange}
                                value={this.state.nome}
                                name="nome"
                                placeholder="Nome Completo"
                                type="text"
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Input
                                autoComplete="email"
                                placeholder="Email"
                                type="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                              ></Input>
                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup>
                              <Input
                                autoComplete="phone"
                                placeholder="Telefone"
                                type="phone"
                                value={this.state.phone}
                                onChange={this.handleChange}
                                name="phone"
                              ></Input>
                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup>
                              <Input
                                autoComplete="city"
                                placeholder="Cidade"
                                type="text"
                                value={this.state.city}
                                onChange={this.handleChange}
                                name="city"
                              ></Input>
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  value={this.state.creci}
                                  onChange={this.handleChange}
                                  name="creci"
                                ></Input>
                                <span className="form-check-sign"></span>
                                Possui Creci?
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col className="ml-auto mr-auto" md="4">
                            <Select
                              className="react-select react-select-info"
                              classNamePrefix="react-select"
                              name="agendamento"
                              onChange={(e) => {
                                this.setState({ agendamento: e.label });
                              }}
                              options={this.agendamentoOption()}
                              placeholder="Dia da Entrevista"
                            />
                            <Button
                              href="#"
                              id="agendamento-btn"
                              block
                              className="btn-round"
                              color="info"
                              type="button"
                              onClick={this.handleSubmit}
                              disabled={this.state.submitIsLoading}
                            >
                              {!this.state.submitIsLoading
                                ? "AGENDAR ENTREVISTA"
                                : "Carregando..."}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
