import React, { Component } from 'react'
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";


export class PassoAPasso extends Component {
    render() {
        return (
            <div
                className="features-8 section-image"
                style={{
                    backgroundImage: "url(" + require("assets/img/bg30.jpg") + ")"
                }}
                >
                <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">Passo a passo</h2>
                </Col>
                <Container>
                    <Row>
                    <Col md="3">
                        <Card>
                            <div className="card-image">
                                <img
                                alt="..."
                                className="rounded"
                                src={require("assets/img/bg28.jpg")}
                                ></img>
                            </div>
                            <div className="info text-center">
                                <div className="icon">
                                <i className="now-ui-icons ui-1_email-85"></i>
                                </div>
                                <h4 className="info-title">Faça um Cadastro</h4>
                                <p className="description">
                                <b>Se cadastre no formulário acima e agende uma entrevista</b>
                                </p>
                            </div>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card>
                            <div className="card-image">
                                <img
                                alt="..."
                                className="rounded"
                                src={require("assets/img/bg26.jpg")}
                                ></img>
                            </div>
                            <div className="info text-center">
                                <div className="icon">
                                <i className="now-ui-icons ui-1_calendar-60"></i>
                                </div>
                                <h4 className="info-title">Venha Conhecer</h4>
                                <p className="description">
                                    <b>Participe da entrevista coletiva e conheça a <i>Kallas Vendas</i></b>
                                </p>
                            </div>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card>
                        <div className="card-image">
                            <img
                            alt="..."
                            className="rounded"
                            src={require("assets/img/pig.jpg")}
                            ></img>
                        </div>
                        <div className="info text-center">
                            <div className="icon">
                                <i className="now-ui-icons business_money-coins"></i>
                            </div>
                            <h4 className="info-title">Pagamos 70% do TTI</h4>
                            <p className="description">
                                <b>Pagamos 70% do curso Técnico em Transações Imobiliárias para você</b>
                            </p>
                        </div>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card>
                        <div className="card-image">
                            <img
                            alt="..."
                            className="rounded"
                            src={require("assets/img/bg29.jpg")}
                            ></img>
                        </div>
                        <div className="info text-center">
                            <div className="icon">
                                <i className="now-ui-icons business_briefcase-24"></i>
                            </div>
                            <h4 className="info-title">Se torne um Corretor</h4>
                            <p className="description">
                                <b>Estagie na <i>KV</i> e comece a atuar como um Corretor de Imóveis</b>
                            </p>
                        </div>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default PassoAPasso
