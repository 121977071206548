import React, { Component } from 'react'

import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Button,
  } from "reactstrap";

  
import Select from "react-select";
import axios from 'axios';
import qs from 'qs';

const salao = 0;
const parcerias = 1;

const theo = '74';
const eiras = '72';
const pedigree = '71';
const valeria = '75';

export default class CadastroCorretor extends Component {
    state = {
        nome: '',
        dataNascimento: '',
        sexo: '',
        cpf: '',
        rg: '',
        orgao: '',
        nacionalidade: '',
        naturalidade: '',
        email: '',
        dddCelular: '',
        celular: '',
        dddFixo: '',
        fixo: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        creci: '',
        validade: '',
        filiacao: '',
        escolaridade: '',
        formacao: '',
        empresaAnterior: '',
        cargoAnterior: '',
        apelido: '',
        gerenteSalao: '',
        gerenteParcerias: '',

        parcerias: [],
        salao: [],

        navPill: 0,
        navPillDiretorSalao: 0,
        navPillDiretorParcerias: 0,

        selectedRGFront: null,
        selectedRGBack: null,
        selectedFoto: null,
        selectedComprovanteEndereco: null,
        selectedCreciFront: null,
        selectedCreciBack: null,

        photoFile: null,
        enderecoDisabled: false,

        corretoresArr: [],
        apelidosArr: [],
        uploading: false,

        bairro: '',
        uf: '',
        cidade: '',
    }

    getToken = async () => {
        const postTokenParams = {
                username: 'vitriu',
                password: '8KTmm0cwYxiSFL6oMiJ0IWi4',
                grant_type: 'password'
        };
        let res = await axios.post('https://apikallas.imobflow.com.br/api/token', qs.stringify(postTokenParams));
        return res.data.access_token;
    }

    getHeaders = (token) => {
        return {headers:{'Authorization': `bearer ${token}`}}
    }

    async componentDidMount() {
        let token = await this.getToken();
        if(token){
            let apelidosRes = await axios.get('https://apikallas.imobflow.com.br/api/corretor/ListagemApelidos', this.getHeaders(token));
            let statusRes = await axios.get('https://apikallas.imobflow.com.br/api/corretor/ListagemStatus', this.getHeaders(token));
            let corretoresRes = await axios.get('https://apikallas.imobflow.com.br/api/corretor/Listagem', this.getHeaders(token));
            let dirs = corretoresRes.data.filter(user => user.Funcao && user.Funcao.includes('DIRETOR VENDAS'));
            let salao = dirs.map((dir, i) => {
                return {
                    diretor: dir.Apelido,
                    gerentes: corretoresRes.data.filter(user => user.Funcao && user.EmpresaVendaId && user.Funcao.includes('GERENTE') && user.EmpresaVendaId == dir.EmpresaVendaId)
                }
            });
            console.log(statusRes.data)
            let parcerias = corretoresRes.data.filter(user => user.EmpresaVendaId && user.EmpresaVendaId == valeria);
    

            if(apelidosRes.data && corretoresRes.data && salao.length > 0){
                this.setState({
                    corretoresArr: corretoresRes.data,
                    apelidosArr: apelidosRes.data.filter(a => a.Status === 'Disponivel'),
                    salao: salao,
                    parcerias: [{
                        diretor: 'Valéria', gerentes: parcerias
                    }],
                })
            }
        }else{
            alert('Verifique sua conexão com a internet.');
        }
    }

    getAddressFromCep = async () => {
        let res = await axios.get('https://viacep.com.br/ws/'+this.state.cep+'/json/');
        if(res.data.logradouro){
            this.setState({endereco: res.data.logradouro, enderecoDisabled: true, uf: res.data.uf, bairro: res.data.bairro, cidade: res.data.localidade});
        }else{
            this.setState({endereco: 'Endereço Inválido', enderecoDisabled: false, uf: res.data.uf, bairro: res.data.bairro, cidade: res.data.localidade});
        }
    }
    
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    
    handleFileChange = (e) => {
        this.setState({ [e.target.name]: e.target.files[0] });
    }

    handlePhotoChange = (e) => {        
        this.setState({ photoFile: URL.createObjectURL(e.target.files[0])});
        this.handleFileChange(e);
    }

    uploadDocs = async (token, corretorId) => {

        //ENVIA FOTO
        let formDataFoto = new FormData();
        formDataFoto.append('file', this.state.selectedFoto);
        let resFoto = await axios.post('https://apikallas.imobflow.com.br/api/corretor/FotoPerfil/' + corretorId, formDataFoto, this.getHeaders(token));
        console.log('Envio Foto: ', resFoto);
        if(resFoto && resFoto.data && resFoto.data.OK){
            //ANEXA FOTO
            let formDataFotoAnexo = new FormData();
            formDataFotoAnexo.append('file', this.state.selectedFoto);
            let resFotoAnexo = await axios.post('https://apikallas.imobflow.com.br/api/corretor/IncluirDocumento/'+ corretorId +'/387', formDataFotoAnexo, this.getHeaders(token));
            console.log('Anexo Foto: ', resFotoAnexo);
            if(resFotoAnexo && resFotoAnexo.data && resFotoAnexo.data.OK){
                
                //ANEXA RG FRENTE
                let formDataRGFront = new FormData();
                formDataRGFront.append('file', this.state.selectedRGFront);
                let resRGFront = await axios.post('https://apikallas.imobflow.com.br/api/corretor/IncluirDocumento/'+ corretorId +'/428', formDataRGFront, this.getHeaders(token));
                console.log('Anexo RG Frente: ', resRGFront);
                if(resRGFront && resRGFront.data && resRGFront.data.OK){
                 
                    //ANEXA RG VERSO
                    let formDataRGBack = new FormData();
                    formDataRGBack.append('file', this.state.selectedRGBack);
                    let resRGBack = await axios.post('https://apikallas.imobflow.com.br/api/corretor/IncluirDocumento/'+ corretorId +'/428', formDataRGBack, this.getHeaders(token));                    
                    console.log('Anexo RG Verso: ', resRGBack);      
                    if(resRGBack && resRGBack.data && resRGBack.data.OK){
                        //ANEXA COMPROVANTE DE ENDEREÇO
                        let formDataEndereco = new FormData();
                        formDataEndereco.append('file', this.state.selectedComprovanteEndereco);
                        let resEndereco = await axios.post('https://apikallas.imobflow.com.br/api/corretor/IncluirDocumento/'+ corretorId +'/429', formDataEndereco, this.getHeaders(token));                     
                        console.log('Anexo Endereços: ', resEndereco);      
                        if(resEndereco && resEndereco.data && resEndereco.data.OK){
                            
                            //ANEXA CRECI FRENTE
                            let formDataCreciFrente = new FormData();
                            formDataCreciFrente.append('file', this.state.selectedCreciFront);
                            let resCreciFrente = await axios.post('https://apikallas.imobflow.com.br/api/corretor/IncluirDocumento/'+ corretorId +'/385', formDataCreciFrente, this.getHeaders(token));                     
                            console.log('Anexo CRECI Frente: ', resCreciFrente);      
                            if(resCreciFrente && resCreciFrente.data && resCreciFrente.data.OK){
                                //ANEXA CRECI FRENTE
                                let formDataCreciVerso = new FormData();
                                formDataCreciVerso.append('file', this.state.selectedCreciBack);
                                let resCreciVerso = await axios.post('https://apikallas.imobflow.com.br/api/corretor/IncluirDocumento/'+ corretorId +'/385', formDataCreciVerso, this.getHeaders(token));                     
                                console.log('Anexo CRECI Verso: ', resCreciVerso);      
                                if(resCreciVerso && resCreciVerso.data && resCreciVerso.data.OK){
                                    this.submitOk();
                                }else{
                                    alert('Algo deu errado, verifique sua conexão com a internet.'); 
                                    console.error(resCreciVerso);        
                                }
                            }else{
                                alert('Algo deu errado, verifique sua conexão com a internet.');
                                console.error(resCreciFrente);               
                            }
                        }else{
                            alert('Algo deu errado, verifique sua conexão com a internet.');
                            console.error(resEndereco);               
                        }
                        
                    }else{
                        alert('Algo deu errado, verifique sua conexão com a internet.');  
                        console.error(resRGBack);             
                    }
                }else{
                    alert('Algo deu errado, verifique sua conexão com a internet.');
                    console.error(resRGFront);       
                }
            }else{
                alert('Algo deu errado, verifique sua conexão com a internet.');
                console.error(resFotoAnexo);            
            }

        }else{
            alert('Algo deu errado, verifique sua conexão com a internet.');
            console.error(resFoto);
        }
    }

    submitOk = () => {
        alert('Cadastro feito com sucesso!')
        this.setState({uploading: false});
        window.location.reload(false);
    }
    submit = async (e) => {
        let gerente = this.state.gerenteSalao !== '' ? this.state.corretoresArr.filter(gerente => gerente.Apelido === this.state.gerenteSalao.value) :
        this.state.corretoresArr.filter(gerente => gerente.Apelido === this.state.gerenteParcerias.value);

        console.log(gerente[0].Id, ' --- ', gerente[0].EmpresaVenda)
        e.preventDefault();
              

        if(this.state.nome !== '' && this.state.dataNascimento !== '' && this.state.sexo !== '' && this.state.nacionalidade !== '' &&
            this.state.naturalidade !== '' && this.state.email !== '' && this.state.dddCelular !== '' && this.state.celular !== '' && this.state.cep !== '' &&
            this.state.endereco !== '' && this.state.numero !== '' && this.state.creci !== '' && this.state.validade !== '' && this.state.cargoAnterior !== '' &&
            this.state.filiacao !== '' && this.state.escolaridade !== '' && this.state.apelido !== '' && (this.state.gerenteSalao !== '' || this.state.gerenteParcerias !== '')){
                
                if(this.state.selectedRGFront !== '' && this.state.selectedRGBack !== '' && this.state.selectedFoto !== '' && this.state.selectedComprovanteEndereco !== '' && 
                this.state.selectedCreciFront !== '' && this.state.selectedCreciBack !== ''){

                    this.setState({uploading: true});
                    let gerente = this.state.gerenteSalao !== '' ? this.state.corretoresArr.filter(gerente => gerente.Apelido === this.state.gerenteSalao.value) :
                    this.state.corretoresArr.filter(gerente => gerente.Apelido === this.state.gerenteParcerias.value);

                    let token = await this.getToken();
                    let postSubmitParams = {
                        Nome: String(this.state.nome),
                        Email: String(this.state.email),
                        DDD_celular: String(this.state.dddCelular),
                        TEL_celular: String(this.state.celular),
                        CRECI: String(this.state.creci),
                        CPF_CNPJ: String(this.state.cpf),
                        UsuarioSuperiorId: parseInt(gerente[0].Id),

                        EmpresaVendaId: parseInt(gerente[0].EmpresaVendaId),
                        ApelidoId: parseInt(this.state.apelidosArr.find(a => a.Apelido === this.state.apelido.value).Id),
                        StatusId: 748,
                        VincularTodosOsProdutosDisponiveis: true,
                        Nacionalidade: String(this.state.nacionalidade),
                        Sexo: String(this.state.sexo),
                        DataNascimento: String(this.state.dataNascimento),
                        CEP: String(this.state.cep),
                        Endereco: String(this.state.endereco),
                        EnderecoNumero: String(this.state.numero),
                        EnderecoComplemento: this.state.endereco + ', ' + this.state.numero,
                        Bairro: this.state.bairro,
                        Cidade: this.state.cidade,
                        UF: this.state.uf,
                        DataValidadeCreci: String(this.state.validade),
                        RG: String(this.state.rg),
                        RGOrgao: String(this.state.orgao),
                        FuncaoId: 1039,                       
                        Profissao: this.state.cargoAnterior,
                    }
                    let res = await axios.post('https://apikallas.imobflow.com.br/api/corretor/Incluir', qs.stringify(postSubmitParams), this.getHeaders(token));
                    console.log(res.data)
                    if(res.data.OK === true){
                        let newId = res.data.Dados;
                        this.uploadDocs(token, newId);
                    }else{
                        alert('Um erro ocorreu: ' + res.data.Mensagem)
                    }

                }else{
                    alert('Também anexe todos os documentos necessários.')
                }

        }else{
            alert('Por favor, preencha todos os campos marcados com um *.')
        }
    }

    render() {
        return (
            <div style={{width: '100vw',            
                background: "url(" + require("assets/img/book.jpg") + ") center repeat",
                backgroundPositionY: '-200px'
            }}>
                <div style={{width: '100vw',            
                    background: "url(" + require("assets/img/sign.jpg") + ") center no-repeat",
                    backgroundPositionY: '-00px'
                }}>
                    <Container>
                        <Row>
                            <Card>
                                <CardBody>
                                    <Col xs={12} style={{textAlign: 'center'}}>
                                        <h2 className='title'>Cadastro de Corretor Kallas Vendas</h2>
                                        <h4><b className='description'>Preencha com seus dados para completar seu cadastro no RH.</b></h4>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Row>
                        <br />
                        <Form onSubmit={this.submit}>
                            <Row>
                                <Col sm={6}>
                                    <Card>
                                        <CardBody>
                                            <h3 className='title'>Dados Pessoais</h3>
                                                <FormGroup>
                                                    <Row>
                                                        <Col md={12}>
                                                            <label>Nome Completo *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Nome Completo'
                                                                name='nome'
                                                                value={this.state.nome}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>Data de Nascimento *</label>
                                                            <Input
                                                                type='date'
                                                                name='dataNascimento'
                                                                value={this.state.dataNascimento}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label>Sexo *</label>
                                                            <Select                                                    
                                                                className="react-select react-select-primary"
                                                                classNamePrefix="react-select"
                                                                placeholder= 'Sexo'
                                                                name='sexo'
                                                                value={this.state.sexo}
                                                                onChange={value => this.setState({sexo: value})}
                                                                options={[
                                                                    {
                                                                        value: 'Masculino',
                                                                        label: 'Masculino'
                                                                    },
                                                                    {
                                                                        value: 'Feminino',
                                                                        label: 'Feminino'
                                                                    },
                                                                ]}
                                                            >

                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                        
                                                    <br />
                                                    <Row>
                                                        <Col md={4}>
                                                            <label>CPF *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='CPF'
                                                                name='cpf'
                                                                value={this.state.cpf}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <label>RG *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='RG'
                                                                name='rg'
                                                                value={this.state.rg}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <label>Orgão Expedidor *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Orgão Expedidor'
                                                                name='orgao'
                                                                value={this.state.orgao}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>Nacionalidade *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Nacionalidade'
                                                                name='nacionalidade'
                                                                value={this.state.nacionalidade}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label>Naturalidade *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Naturalidade'
                                                                name='naturalidade'
                                                                value={this.state.naturalidade}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={6}>
                                    <Card>
                                        <CardBody>
                                                <FormGroup>                                                
                                                    <Row>
                                                        <Col md={12}>
                                                            <h3 className='title'>Dados de Contato</h3>
                                                            <label>Email *</label>
                                                            <Input
                                                                type='email'
                                                                placeholder='Email'
                                                                name='email'
                                                                value={this.state.email}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />                                                
                                                    <Row>
                                                        <Col md={4}>
                                                            <label>DDD Celular *</label>
                                                            <Input
                                                                type='number'
                                                                placeholder='DDD Celular'
                                                                name='dddCelular'
                                                                value={this.state.dddCelular}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={8}>
                                                            <label>Celular *</label>
                                                            <Input
                                                                type='tel'
                                                                placeholder='Celular'
                                                                name='celular'
                                                                value={this.state.celular}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Col md={4}>
                                                            <label>DDD Telefone Fixo</label>
                                                            <Input
                                                                type='number'
                                                                placeholder='DDD Telefone Fixo'
                                                                name='dddFixo'
                                                                value={this.state.dddFixo}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={8}>
                                                            <label>Telefone Fixo</label>
                                                            <Input
                                                                type='tel'
                                                                placeholder='Telefone Fixo'
                                                                name='fixo'
                                                                value={this.state.fixo}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />                                             
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>CEP *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='CEP'
                                                                name='cep'
                                                                value={this.state.cep}
                                                                onBlur={this.getAddressFromCep}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label>Endereço *</label>
                                                            <Input
                                                                disabled={this.state.enderecoDisabled}
                                                                type='text'
                                                                placeholder='Endereço'
                                                                name='endereco'
                                                                value={this.state.endereco}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>Número *</label>
                                                            <Input
                                                                type='number'
                                                                placeholder='Número'
                                                                name='numero'
                                                                value={this.state.numero}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label>Complemento</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Complemento'
                                                                name='complemento'
                                                                value={this.state.complemento}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Card>
                                        <CardBody>
                                                <FormGroup>                                         
                                                        <h3 className='title'>Dados Profissionais</h3>       
                                                    <Row>
                                                        <Col md={4}>
                                                            <label>CRECI *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='CRECI'
                                                                name='creci'
                                                                value={this.state.creci}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <label>Data de Validade *</label>
                                                            <Input
                                                                type='date'
                                                                name='validade'
                                                                value={this.state.validade}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <label>Filiação *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Filiação'
                                                                name='filiacao'
                                                                value={this.state.filiacao}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />                                             
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>Escolaridade *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Escolaridade'
                                                                name='escolaridade'
                                                                value={this.state.escolaridade}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label>Formação</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Formação'
                                                                name='formacao'
                                                                value={this.state.formacao}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />                                             
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>Empresa Anterior</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Empresa Anterior'
                                                                name='empresaAnterior'
                                                                value={this.state.empresaAnterior}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label>Cargo Anterior *</label>
                                                            <Input
                                                                type='text'
                                                                placeholder='Cargo Anterior'
                                                                name='cargoAnterior'
                                                                value={this.state.cargoAnterior}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Col md={12}>
                                                            
                                                        <label>Apelido *</label>
                                                            <Select                                                    
                                                                className="react-select react-select-primary"
                                                                classNamePrefix="react-select"
                                                                placeholder= 'Apelido'
                                                                name='apelido'
                                                                value={this.state.apelido}
                                                                onChange={value => this.setState({apelido: value})}
                                                                options={this.state.apelidosArr.map(apelido => {
                                                                    return {value: apelido.Apelido, label: apelido.Apelido}
                                                                })}
                                                            >

                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={6}>
                                    <Card>
                                        <CardBody>
                                            <h3 className='title'>Diretoria e Equipe</h3>
                                            <Nav className="nav-pills-primary nav-pills-icons" pills role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        className={this.state.navPill === 0 ? "active" : ""}
                                                        href='#'
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.setState({navPill: 0, gerenteParcerias: ''})
                                                        }}                                            
                                                    >
                                                        Salão
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={this.state.navPill === 1 ? "active" : ""}
                                                        href='#'
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.setState({navPill: 1, gerenteSalao: ''})
                                                        }}         
                                                    >
                                                        Parcerias
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent className="tab-space" activeTab={"navPill" + this.state.navPill}>
                                                <TabPane tabId='navPill0'>                                                
                                                    <Row>
                                                        <Col md={4}>
                                                            <Nav className="nav-pills-info flex-column" pills>
                                                                {this.state.salao.map((diretoria, i) => {
                                                                    return(
                                                                        <NavItem key={'diretoria'+i}>
                                                                            <NavLink
                                                                                className={this.state.navPillDiretorSalao === i ? "active" : ""}
                                                                                href='#'
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.setState({navPillDiretorSalao: i, gerenteSalao: ''})
                                                                                }}         
                                                                            >
                                                                                {diretoria.diretor}
                                                                            </NavLink>
                                                                        </NavItem>
                                                                    )
                                                                })}
                                                            </Nav>
                                                        </Col>
                                                        <Col md={8}>
                                                            <TabContent activeTab={"navPillDiretorSalao" + this.state.navPillDiretorSalao}>
                                                                {this.state.salao.map((diretoria, i) => {
                                                                    return(
                                                                        <TabPane tabId={"navPillDiretorSalao" + i} key={"navPillDiretorSalao" + i}>
                                                                            <Select                                                    
                                                                                className="react-select react-select-primary"
                                                                                classNamePrefix="react-select"
                                                                                placeholder= 'Gerente'
                                                                                name='gerente'
                                                                                value={this.state.gerenteSalao}
                                                                                onChange={value => this.setState({gerenteSalao: value})}
                                                                                options={diretoria.gerentes.map((gerente, i) => {
                                                                                    return({value: String(gerente.Apelido), label: String(gerente.Apelido)})
                                                                                })}
                                                                            />
                                                                        </TabPane>
                                                                    )
                                                                })}
                                                        </TabContent>               
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId='navPill1'>
                                                <Row>
                                                        <Col md={4}>
                                                            <Nav className="nav-pills-info flex-column" pills>
                                                                {this.state.parcerias.map((diretoria, i) => {
                                                                    return(
                                                                        <NavItem key={'diretoria'+i}>
                                                                            <NavLink
                                                                                className={this.state.navPillDiretorParcerias === i ? "active" : ""}
                                                                                href='#'
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.setState({navPillDiretorParcerias: i, gerenteParcerias: ''})
                                                                                }}         
                                                                            >
                                                                                {diretoria.diretor}
                                                                            </NavLink>
                                                                        </NavItem>
                                                                    )
                                                                })}
                                                            </Nav>
                                                        </Col>
                                                        <Col md={8}>
                                                            <TabContent activeTab={"navPillDiretorParcerias" + this.state.navPillDiretorParcerias}>
                                                                {this.state.parcerias.map((diretoria, i) => {
                                                                    return(
                                                                        <TabPane tabId={"navPillDiretorParcerias" + i} key={"navPillDiretorParcerias" + i}>
                                                                            <Select                                                    
                                                                                className="react-select react-select-primary"
                                                                                classNamePrefix="react-select"
                                                                                placeholder= 'Gerente'
                                                                                name='gerente'
                                                                                value={this.state.gerenteParcerias}
                                                                                onChange={value => this.setState({gerenteParcerias: value})}
                                                                                options={diretoria.gerentes.map((gerente, i) => {
                                                                                    return({value: String(gerente.Apelido), label: String(gerente.Apelido)})
                                                                                })}
                                                                            />
                                                                        </TabPane>
                                                                    )
                                                                })}
                                                        </TabContent>               
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Card>
                                    <CardBody>
                                        <h3 className='title'>Anexos de Documentos</h3>
                                        <Row>
                                            <Col md={6}>
                                                <br />
                                                <label><strong>RG/CPF/CNH (Frente)</strong></label>
                                                <Input name='selectedRGFront' onChange={this.handleFileChange} type='file' />
                                            </Col>
                                            <Col md={6}>
                                                <br />
                                                <label><strong>RG/CPF/CNH (Verso)</strong></label>
                                                <Input name='selectedRGBack' onChange={this.handleFileChange} type='file' />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6}>
                                                <br />
                                                <label><strong>CRECI (Frente)</strong></label>
                                                <Input name='selectedCreciFront' onChange={this.handleFileChange} type='file' />
                                            </Col>
                                            <Col md={6}>
                                                <br />
                                                <label><strong>CRECI (Verso)</strong></label>
                                                <Input name='selectedCreciBack' onChange={this.handleFileChange} type='file' />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6}>
                                                <br />
                                                <label><strong>Foto ( Para o Crachá )</strong></label>
                                                <Input name='selectedFoto' onChange={this.handlePhotoChange} type='file' />
                                                {this.state.photoFile &&
                                                    <div>
                                                        <br />                                                
                                                        <img style={{width: '120px', height: '120px', borderRadius: '50%'}} src={this.state.photoFile} />
                                                        Esta imagem será usada em seu crachá.
                                                    </div>
                                                }
                                            </Col>
                                            <Col md={6}>
                                                <br />
                                                <label><strong>Comprovante de Endereço</strong></label>
                                                <Input name='selectedComprovanteEndereco' onChange={this.handleFileChange} type='file' />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Row>
                            <Row>
                                <Card>
                                    <CardBody>
                                        <h3 className='title'>Enviar Formulário</h3>
                                        <h4 className='description'>Após ter preenchido todos os campos marcados com " * ", clique em "Enviar Formulário" para completar seu cadastro.</h4>
                                        <Button color='primary' style={{width: '100%', textAlign:'center'}} disabled={this.state.uploading}>Enviar Formulário</Button>
                                    </CardBody>
                                </Card>
                            </Row>
                        </Form>
                    </Container>
                </div>             
            </div>
        )
    }
}
