import React, { Component } from 'react'
// reactstrap components
import {
    Container,
    Row,
    Col,
  } from "reactstrap";

export class SobreAKallas extends Component {
    render() {
        return (
            <div className="features-3">
                <Container>
                    <Row>
                    <Col className="ml-auto mr-auto" md="8">
                        <h2 className="title">Conheça a Kallas</h2>
                        <h4 className="">
                            Ao longo dos seus mais de 30 anos de experiência no mercado, a Kallas cresceu junto com seus clientes, entendendo a importância de boas práticas e do respeito ao entorno na criação de soluções imobiliárias e de engenharia mais eficientes e alinhadas a seu tempo - beneficiando empresa, mundo e sociedade.
                        </h4>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="4">
                        <div className="info info-hover">
                        <div className="icon icon-success icon-circle">
                            <i className="now-ui-icons emoticons_satisfied"></i>
                        </div>
                        <h4 className="info-title">Satisfação de todos os seus clientes</h4>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="info info-hover">
                        <div className="icon icon-primary icon-circle">
                            <i className="now-ui-icons education_atom"></i>
                        </div>
                        <h4 className="info-title">Busca pela melhoria de seus processos</h4>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="info info-hover">
                        <div className="icon icon-info icon-circle">
                            <i className="now-ui-icons business_bank"></i>
                        </div>
                        <h4 className="info-title">Mais de 30 anos de solidez</h4>
                        </div>
                    </Col>
                    </Row>
                </Container>
                </div>
        )
    }
}

export default SobreAKallas
