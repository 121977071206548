import React, { Component } from 'react'
import PageHeader from './Page/PageHeader';
import PassoAPasso from './Page/PassoAPasso';
import SobreAKallas from './Page/SobreAKallas';

export class Page extends Component {
    

    constructor(props){
        super(props);
        this.state = {
            navbarOpen1: false,
            navbarOpen2: false,
            navbarOpen3: false,
        }
    }   

    
    render() {
        
        return (
            <>
                <PageHeader />
                <PassoAPasso />
                <SobreAKallas />
            </>
        )
    }
}

export default Page
